import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // index
    fetchEvidencias(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-social/asistencia-social/visita/${id}/evidencia`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // show
    fetchEvidencia(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-social/asistencia-social/visita/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // store
    addEvidencia(ctx, visitaData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/servicio-social/asistencia-social/visita', visitaData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // update
    updatedEvidencia(ctx, visitaData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/servicio-social/asistencia-social/visita/${visitaData.id}`, visitaData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // delete
    deleteEvidencia(ctx, { visitaId, evidenciaId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-social/asistencia-social/visita/${visitaId}/evidencia/${evidenciaId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
