<template>
  <!-- modal login-->
  <b-modal
    id="modal-visita-form"
    ref="refModalVisitaForm"
    v-model="dialog"
    :title="formTitle"
    size="lg"
    no-close-on-backdrop
    :hide-footer="true"
    no-close-on-esc
    hide-header-close
  >
    <b-form @submit.prevent="onSubmit">
      <b-row class="bg-light-success py-1 rounded">
        <b-col
          cols="12"
          class="d-flex justify-content-center"
        >
          <h3 class="text-uppercase font-weight-bold text-primary">
            <strong class="mr-1">ESTUDIANTE:</strong> {{ visitaData.persona.nombre_completo }}
          </h3>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-center"
        >
          <h4 class="text-danger font-weight-bold font-italic font-weight-bold">
            <strong>DNI:</strong>
            {{ visitaData.persona.numero_documento || 'no hay registro' }}
          </h4>
        </b-col>

      </b-row>

      <hr class="my-1 py-1">

      <b-row>
        <b-col
          md="6"
          sm="12"
        >
          <!-- Input servicio -->
          <b-form-group
            label="Tipo de visita"
          >
            <v-select
              v-model="visitaData.tipo"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsTipos"
              placeholder="Seleccionar"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          sm="12"
        >
          <b-form-group
            label="Fecha de visita"
          >
            <b-form-datepicker
              id="example-datepicker"
              v-model="visitaData.fecha"
              class="mb-1"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group label="Motivo:">
        <b-form-textarea
          v-model="visitaData.motivo"
          placeholder="Describir motivo..."
          rows="3"
          required
          max-rows="6"
        />
      </b-form-group>
      <b-form-group label="Detalles:">
        <b-form-textarea
          v-model="visitaData.detalles"
          placeholder="Describir detalles..."
          rows="3"
          required
          max-rows="6"
        />
      </b-form-group>
      <b-form-group label="Conclusiones:">
        <b-form-textarea
          v-model="visitaData.conclusiones"
          placeholder="Describir conclusiones..."
          rows="3"
          required
          max-rows="6"
        />
      </b-form-group>

      <b-row class="mt-1">
        <b-col>
          <b-button
            variant="success"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
          >
            Guardar
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="close"
          >
            Cerrar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>

</template>

<script>
import {
  BRow, BCol, BForm, BModal, BFormGroup, BFormTextarea, BButton, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {
  ref, watch, toRefs, nextTick, computed,
} from '@vue/composition-api'
import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    BButton,
    vSelect,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  props: {
    persona: {
      type: Object,
      default() {
        return { }
      },
    },
    editedItem: {
      type: Object,
      default() {
        return { }
      },
    },
  },
  data() {
    return {
      selected: null,
      selectedEstado: null,
      optionsTipos: ['domiciliaria', 'hospitalaria', 'otros'],
    }
  },
  setup(props, { emit }) {
    const { persona } = toRefs(props)
    const { editedItem } = toRefs(props)

    const editedIndex = ref(-1)
    const dialog = ref(false)
    const toast = useToast()

    const blankVisitaData = {
      persona_usuario: {},
      persona: {},
      id: null,
      servidor_publico_id: 500,
      usuario_id: null,
      motivo: null,
      detalles: null,
      conclusiones: null,
      tipo: null,
      fecha: null,
    }

    const visitaData = ref(JSON.parse(JSON.stringify(blankVisitaData)))

    const resetuserData = () => {
      visitaData.value = JSON.parse(JSON.stringify(blankVisitaData))
    }

    const close = () => {
      dialog.value = false
      nextTick(() => {
        resetuserData()
        editedIndex.value = -1
      })
    }

    const formTitle = computed(() => (editedIndex.value === -1 ? 'Nuevo' : `Editar N° ${editedIndex.value}`))

    watch(dialog, () => {
      // eslint-disable-next-line no-unused-expressions
      dialog.value || close()
    })

    watch(persona, () => {
      resetuserData()
      visitaData.value.usuario_id = persona.value.usuario_id
      visitaData.value.persona = persona.value
      editedIndex.value = -1
    })

    watch(editedItem, () => {
      editedIndex.value = editedItem.value.id
      // Datos de triaje
      visitaData.value = editedItem.value
      visitaData.value.persona = editedItem.value.persona_usuario
      visitaData.value.servidor_publico_id = editedItem.value.servidor_publico_id
      visitaData.value.usuario_id = editedItem.value.usuario_id
    })

    const onSubmit = () => {
      if (editedIndex.value > -1) {
      // vamos a editar

        store.dispatch('visitas/updatedVisita', visitaData.value)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: '¡Actualización exitosa',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            dialog.value = false
            emit('refresh-data-visitas-table')
          })
      } else {
        store.dispatch('visitas/addVisita', visitaData.value)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: '¡Registro exitoso!',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            dialog.value = false
            emit('refresh-data-visitas-table')
          })
      }
    }

    return {
      visitaData,
      resetuserData,
      onSubmit,
      close,
      dialog,
      formTitle,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
